@media only screen and (max-width: 1100px) {
    .column_count_colus .dashColswe {flex-basis: 32%;}
}
@media only screen and (max-width: 991px) {
    .e-app_wrappperr li .e-appdetails{
        width: 60%;
    }
}
@media only screen and (max-width: 900px) {
    .close_sidebarbtn {
        background: transparent;
        border: 0px;
        font-size: 28px;
        float: right;
        line-height: normal;
        font-weight: 500;
        margin-right: 5px;
    }
    .toogle_bar_btn {
        background: transparent;
        border: 0px;
        margin: 0 8px 0 0px;
    }
    .user_DETAILS {
        font-size: 13px;
        padding: 5px 10px;
    }
    .user_DETAILS .avatrshow {
        width: 22px;
    }
    .logoutbtn, .logoutbtn:focus, .logoutbtn:active {
        font-size: 18px;
    }
    .add_e-appbtn {
        font-size: 19px;
    }
    .head_section h4 {
        flex: 1;
    }
    .toogle_bar_btn img {
        width: 24px;
    }
    .head_section {
        padding: 0px 0px 0px 10px;
    }
    .sidebar {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 9;
        width: 290px;
        box-shadow: -1px 0px 12px 1px #333;
    }
    .panelwrapper {
        width: 100%;
    }
    .flex-column .nav-link {
        flex-direction: row;
        padding: 9px 15px;
    }
    .flex-column .nav-link i {
        margin-right: 10px;
        margin-bottom: 0px;
    }
    .adminmain {
        align-items: self-start;
        column-gap: initial;
    }
}
@media only screen and (max-width: 800px) {
    .respoactionproduct {
        display: flex;
        height: 82px;
        align-items: center; 
    }
    .login_wrapper {flex-direction: column;}
    .productQuantyset, .addtoe-appebtn, .addtoe-appebtn:focus, .addtoe-appebtn:active {
        width: 120px;
    }
    .login_wrap_slider img {display: none;}
    .login_wrap_slider {padding: 20px 15px;}
    .login_wrap_slider h4 {margin: 0px 0 9px;font-size: 30px;}
    .login_wrap_slider p {font-size: 17px;}
    .login_form_wraps {padding: 26px 30px;}
}
@media only screen and (max-width: 767px) {
    .respoactionUsers {
        display: flex;
        height: 60px;
        align-items: center; 
    }
    .column_count_colus .dashColswe {flex-basis: 48%;}
    .panelContentwrapper {height: calc(100vh - 54px);overflow: auto;}
    .addtoe-appebtn {width: 110px;}
    .panelContentwrapper {padding: 10px 10px;}
}
@media only screen and (max-width: 700px) {
    .respoactionSupplier {
        display: flex;
        height: 60px;
        align-items: center; 
    }
    .e-app_wrappperr li .avatrshow {
        width: 80px;
        height: 80px;
    }
    .e-app_wrappperr li .e-appdetails h4 {
        font-size: 15px;
    }
    .e-app_wrappperr li .e-appdetails .size_product li {
        font-size: 11px;
    }
    .viewe-appbtn, .removere-app {
        font-size: 12px;
    }
    .e-app_wrappperr li .e-appbtn_wraps h2 {font-size: 21px;}
    .responsiveSearchBox {
        float: left;
        width: auto;
        position: relative;
        margin: 1px 0px 0px;
    }
    .responsiSearcFiled {
        float: left;
        width: auto;
        position: absolute;
        top: 32px;
        left: 0;
        z-index: 99;
        padding: 5px 6px;
        background-color: #fff;
        border: 1px solid #ddd;
        box-shadow: 1px 0px 14px -3px #ccc;
        border-radius: 7px;
    }
    .icon-tabler-search-respo {
        width: 20px;
        height: 20px;
    }
    .head_cmn_btn, .head_cmn_btn:focus, .head_cmn_btn:active {
        font-size: 12px;
        margin: 0 0 0 5px;
    }
    .card-header-New select {
        height: 33px !important;
        width: 75px;
    }
    .card-header-right select:first-child {
        margin-right: 5px;
    }
    .paginationCustom li button {
        width: 25px;
        height: 25px;
        min-width: 25px;
        font-size: 12px;
        margin: 0px 1px;
    }
    ul.tablehead_tabs li {
        padding: 6px 15px;
        font-size: 14px;
    }
    .panelContentwrapper .table thead th {
        font-size: 12px;
        padding: 10px 7px;
    }
    .panelContentwrapper .table tbody td {
        font-size: 12px;
        padding: 8px 7px;
    }
}
@media only screen and (max-width: 640px) {
    .e-app_wrappperr li .e-appdetails {
        width: 50%;
    }
    .e-app_wrappperr li .e-appbtn_wraps {
        width: 110px;
    }
    .e-app_wrappperr li .e-appbtn_wraps .quantity_product {
        padding: 10px 0px 6px;
    }
}
@media only screen and (max-width: 540px) {
    .Toastify__toast-container {width: 100%;left: 0;top: 0px;}
    .login_wrap_slider h4 {font-size: 26px;}
    .login_form_wraps {
        padding: 13px 18px 18px;
    }
    .login_wrapper {
        width: 96%;
    }
    .login_wrap_slider p {
        width: 100%;
    }
    .notformsection p {
        font-size: 16px;
    }
    .notformsection h5 {
        font-size: 17px;
    }
    .e-app_mobile_responsive {
        float: left;
        width: 100%;
        display: flex;
        align-items: center;
    }
    .card-header-New.card-header-New-header .dropdown_wrapper {
        width: 82%;
    }
    .card-header-New.card-header-New-header .dropdown_wrapper .dropdown-toggle {
        float: left;
        width: 100%;
    }
    .card-header-New.card-header-New-header .searchBoxwithbtn {
        width: 100%;
    }
    .supaTotalammount h4 {
        font-size: 15px;
    }
    .checkoutbtn, .checkoutbtn:focus, .checkoutbtn:active {
        padding: 6px 16px;
        font-size: 13px;
        margin: 0 0 0 10px;
    }
    .e-app_wrappperr li .e-appbtn_wraps {width: 140px;}
    .viewe-appbtn, .removere-app {font-size: 14px;}
    .productQuantyset .btn {margin: 0px 5px !important;}
    .e-app_wrappperr li .e-appdetails {width: 100%;}
    .e-app_wrappperr li .avatrshow {width: 150px;height: 150px;margin: 0px 0px 10px 0px;}
    .add_e-appbtn {padding: 0px 15px 0px 5px;}
    .head_section h4 {font-size: 14px;}
    .sidebar {width: 100%;}
    .column_count_colus .dashColswe {flex-basis: 100%;}
    .add_e-appbtn .badge {right: 7px;top: 4px;font-size: 9px;}
    .add_e-appbtn {margin-right: 2px;}
    .card-header-New {margin: 0 0 10px;}
}